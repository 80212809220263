<div class="navbar navbar-default">
  <div class="container">
    <div id="navbar-main">
      <!--
      <ul class="nav navbar-nav">
        <li><a routerLink="/billentry">Bills</a></li>
      </ul>
    -->
      <ul class="nav navbar-nav navbar-right">
        <!--
        <li *ngIf="!auth.isLoggedIn()"><a routerLink="/login">Sign in</a></li>
        <li *ngIf="auth.isLoggedIn()">
          <a routerLink="/profile">{{ auth.getUserDetails()?.name }}</a>
        </li>
        <!--
        <li *ngIf="auth.isLoggedIn()"><a (click)="auth.logout()">Logout</a></li>
        -->
      </ul>
    </div>
  </div>
</div>
<app-smart-plug></app-smart-plug>
<router-outlet></router-outlet>
