export enum BillEntryCategory {
  groceries, furniture, cinema, restaurant, car, train, money, vacation,  gift
}

export class BillEntry{

  _id: string;
  comment: string;
  amount: number;
  owner: string;
  billEntryCategory: BillEntryCategory;
  isDeactivated: boolean;
  timestamp: Date;

  constructor(){
    this.amount = 0;
    this.owner = "ich";
    this.billEntryCategory = BillEntryCategory.groceries;
    this.isDeactivated = false;
  }

  public static getFaIconClassFromBillEntry(billEntry: BillEntry): string{
    let baseClass = "fa ";
    switch(billEntry.billEntryCategory){
      case BillEntryCategory.groceries: return  baseClass +"fa-shopping-cart";
      case BillEntryCategory.furniture: return baseClass + "fa-home";
      case BillEntryCategory.cinema: return baseClass + "fa-film";
      case BillEntryCategory.restaurant: return baseClass + "fa fa-cutlery";
      case BillEntryCategory.car: return baseClass + "fa-car";
      case BillEntryCategory.train: return  baseClass +"fa-subway";
      case BillEntryCategory.money: return  baseClass +"fa-money";
      case BillEntryCategory.vacation: return  baseClass +"fa-plane";
      case BillEntryCategory.gift: return  baseClass +"fa-gift";
    }
  }
}
