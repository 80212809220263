<div class="container">
  <div class="box">
    <div class="row" style="margin: 0px;">
      <div class="col" style="padding: 7.5px;">
        <button type="button" style="width: 100%;" [class]="smart_plug1Class" (click)='togglePlug("smart_plug1")'>Echo</button>
      </div>
      <div class="col" style="padding: 7.5px;">
        <button type="button" style="width: 100%;" [class]="smart_plug4Class" (click)='togglePlug("smart_plug4")'>Projektor</button>
      </div>
      <div class="col" style="padding: 7.5px;">
        <button type="button" style="width: 100%;" [class]="smart_plug2Class" (click)='togglePlug("smart_plug2")'>Ecke</button>
      </div>
      <div class="col" style="padding: 7.5px;">
        <button type="button" style="width: 100%;" [class]="smart_plug3Class" (click)='togglePlug("smart_plug3")'>Papier</button>
      </div>
    </div>
  </div>
</div>
