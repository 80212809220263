import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from "../authentication.service";

@Component({
  selector: 'app-smart-plug',
  templateUrl: './smart-plug.component.html',
  styleUrls: ['./smart-plug.component.css']
})
export class SmartPlugComponent implements OnInit {

  constructor(private auth: AuthenticationService, private http: HttpClient) { }

  smart_plug1Class:string = "btn btn-sm btn-light disabled";
  smart_plug2Class:string = "btn btn-sm btn-light disabled";
  smart_plug3Class:string = "btn btn-sm btn-light disabled";
  smart_plug4Class:string = "btn btn-sm btn-light disabled";

  toggleInProgess = "";

  ngOnInit(): void {

    let that = this;
    setTimeout(function(){ that.refreshPlugStates(); }, 1);

  }

    refreshPlugStates(){
      this.getStateOfPlugAsClass("smart_plug1");
      this.getStateOfPlugAsClass("smart_plug2");
      this.getStateOfPlugAsClass("smart_plug3");
      this.getStateOfPlugAsClass("smart_plug4");
    }


  async getStateOfPlugAsClass(smartPlugName){
    this.http.post("/api/smartPlugState",{"name":smartPlugName}, this.auth.getAuthHeaderObject()).subscribe(data => {
      if(data["state"]["relay_state"] === 0){
        this[smartPlugName+"Class"] = "btn btn-sm btn-secondary";
      }else{
        this[smartPlugName+"Class"] = "btn btn-sm btn-success";
      }
    },
    (error)=>{
      if(error.status === 404){
        this[smartPlugName+"Class"] = "btn btn-sm btn-light disabled";
      }
    });
  }

  togglePlug(smartPlugName): void{
    if(this.toggleInProgess === smartPlugName){
      return;
    }
    if(this[smartPlugName+"Class"].includes("disabled")){
      return;
    }
    this.toggleInProgess = smartPlugName;

    this.http.post("/api/smartPlugToggle",{"name":smartPlugName}, this.auth.getAuthHeaderObject()).subscribe(data => {
      this.refreshPlugStates();
      this.toggleInProgess = "";
    });
  }

}
