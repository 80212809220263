import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from "../authentication.service";
import { BillEntry, BillEntryCategory } from "./BillEntry";

@Component({
  selector: 'app-bill-entry',
  templateUrl: './bill-entry.component.html',
  styleUrls: ['./bill-entry.component.css']
})
export class BillEntryComponent implements OnInit {

  constructor(private auth: AuthenticationService, private http: HttpClient) { }

  billEntries: Array<BillEntry>;
  balance = 0;
  bills: Array<any>;

  currentBillEntry: BillEntry;
  BillEntryCategory = BillEntryCategory;
  BillEntry = BillEntry;

  ngOnInit(): void {
    this.currentBillEntry = new BillEntry();
    this.currentBillEntry.owner = this.auth.getUserDetails()._id;
    this.loadLast1000BillEntries();
    setInterval(() => { this.loadLast1000BillEntries(); }, 60 * 1000);
  }


  private loadLast1000BillEntries(): void {
    this.http.get("/api/billEntries", this.auth.getAuthHeaderObject()).subscribe(data => {
      const dataAsAny = data as any;
      const billEntries: Array<BillEntry> = dataAsAny.billEntries;
      this.billEntries = billEntries.sort(((x: any, y: any) => x.timestamp - y.timestamp)).reverse();
      this.loadCurrentBalance();
    });
  }

  loadCurrentBalance(): void {
    this.http.get("/api/balances", this.auth.getAuthHeaderObject()).subscribe(data => {
      const dataAsAny = data as any;
      const balances: [any] = dataAsAny.balances;
      this.balance = 0;
      const user = this.auth.getUserDetails();
      for (let b of balances) {
        this.balance += b._id === user._id ? b.balance : b.balance * -1;
      }

    });
  }

  public saveBillEntry(): void {
    let tmpBillEntry = this.currentBillEntry;
    tmpBillEntry.amount = Number(tmpBillEntry.amount.toString().replace(/,/g, "." ));

    this.http.post("/api/billEntry", tmpBillEntry, this.auth.getAuthHeaderObject()).subscribe(data => {
      this.currentBillEntry = new BillEntry();
      this.currentBillEntry.owner = this.auth.getUserDetails()._id;
      this.loadLast1000BillEntries();
    });

  }

  checkInput(): boolean{
    return this.currentBillEntry.amount == null || this.currentBillEntry.amount === 0 || isNaN(Number(this.currentBillEntry.amount.toString().replace(/,/g, "." )));
  }

  formatNumber(x: number): string{
    return (Math.round(x * 100) / 100)?.toString().replace(".", "," );
  }







}
