<div *ngIf="isCurrentUserOwner()" style="width: 17%;margin: auto;">
  <div style="margin-left: 5px;">{{billEntry.timestamp| date | slice:0:6}}</div>
</div>
<div class="box" style="width: 83%;" [style]="isCurrentUserOwner()?'background-color: #dcf8c6;':'background-color: #ced4da'">
 <div class="row">
  <div style="width:10%;">
    <div style="padding-left: 10px;height: 100%;width: 100%;" (click)="!isClosed()?deleteBillEntry():toggleClosed()">
      <i *ngIf="isClosed() || !isCurrentUserOwner()" [class]="BillEntry.getFaIconClassFromBillEntry(billEntry)" aria-hidden="true"></i>
      <i *ngIf="!isClosed() && isCurrentUserOwner()" class="fa fa-trash" aria-hidden="true"></i>
    </div>
  </div>
  <div style="width:35%;" (click)="toggleClosed()">
    <div style="padding-left: 10px;border-left: 1px solid #9eaab6;margin-left: 10px;height: 100%;vertical-align: middle;">{{formatNumber(billEntry.amount)}} €</div>
  </div>
  <div style="width:55%;" (click)="toggleClosed()">
    <div style="padding-left: 10px;border-left: 1px solid #9eaab6;margin-left: 10px;" [style] = "isClosed()? 'text-overflow: ellipsis;white-space: nowrap; overflow: hidden;':'text-overflow: ellipsis;overflow: hidden;'">{{billEntry.comment}}</div>
  </div>
 </div>
</div>
<div *ngIf="!isCurrentUserOwner()" style="width: 17%;margin: auto;">
  <div style="margin-left: 5px;">{{billEntry.timestamp| date | slice:0:6}}</div>
</div>


