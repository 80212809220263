
<h3 style="text-align: center;">Current balance: {{formatNumber(balance)}} €</h3>
<div class="container">
  <div class="row no-gutters">
    <div style="width:15%;" >
      <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="width: 100%;">
        <i [class]="BillEntry.getFaIconClassFromBillEntry(currentBillEntry)" aria-hidden="true"></i>
      </button>
      <div class="dropdown-menu" >
        <a class="dropdown-item" (click)="currentBillEntry.billEntryCategory=BillEntryCategory.groceries"><i class="fa fa-shopping-cart" aria-hidden="true"></i></a>
        <a class="dropdown-item" (click)="currentBillEntry.billEntryCategory=BillEntryCategory.furniture"><i class="fa fa-home" aria-hidden="true"></i></a>
        <a class="dropdown-item" (click)="currentBillEntry.billEntryCategory=BillEntryCategory.cinema"><i class="fa fa-film" aria-hidden="true"></i></a>
        <a class="dropdown-item" (click)="currentBillEntry.billEntryCategory=BillEntryCategory.restaurant"><i class="fa fa-cutlery" aria-hidden="true"></i></a>
        <a class="dropdown-item" (click)="currentBillEntry.billEntryCategory=BillEntryCategory.car"><i class="fa fa-car" aria-hidden="true"></i></a>
        <a class="dropdown-item" (click)="currentBillEntry.billEntryCategory=BillEntryCategory.train"><i class="fa fa-subway" aria-hidden="true"></i></a>
        <a class="dropdown-item" (click)="currentBillEntry.billEntryCategory=BillEntryCategory.money"><i class="fa fa-money" aria-hidden="true"></i></a>
        <a class="dropdown-item" (click)="currentBillEntry.billEntryCategory=BillEntryCategory.vacation"><i class="fa fa-plane" aria-hidden="true"></i></a>
        <a class="dropdown-item" (click)="currentBillEntry.billEntryCategory=BillEntryCategory.gift"><i class="fa fa-gift" aria-hidden="true"></i></a>
      </div>
    </div>
    <div style="width:37.5%" >
      <div class="input-group mb-1">
        <div class="input-group-prepend">
          <span class="input-group-text">€</span>
        </div>
        <input type="text" class="form-control" [(ngModel)]="currentBillEntry.amount">
      </div>
    </div>
    <div style="width:37.5%" >
      <input type="text" class="form-control" placeholder="comment" [(ngModel)]="currentBillEntry.comment">
    </div>
    <div style="width:10%" >
      <button [disabled]="checkInput()" (click)="saveBillEntry()" type="button" class="btn btn-success"><i class="fa fa-check" aria-hidden="true"></i></button>
    </div>
  </div>
  <ng-container  *ngFor="let row of billEntries" >
    <app-bill-entry-row [hidden]="row.isDeactivated" (deleted)="loadCurrentBalance()"  [billEntry] = row class="row no-gutters" style="padding-top: 5px;"></app-bill-entry-row>
  </ng-container>
</div>

