import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';

import { AuthenticationService, } from "../authentication.service";
import { BillEntry, BillEntryCategory } from "../bill-entry/BillEntry";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-bill-entry-row',
  templateUrl: './bill-entry-row.component.html',
  styleUrls: ['./bill-entry-row.component.css']
})
export class BillEntryRowComponent implements OnInit {

  constructor(private auth: AuthenticationService, private http: HttpClient) { }

  static isClosedId: string;

  BillEntryCategory = BillEntryCategory;
  BillEntry = BillEntry;
  @Input()
  billEntry: BillEntry;

  @Output() deleted: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
  }

  isCurrentUserOwner(): boolean{
    let currentUser = this.auth.getUserDetails();
    return currentUser._id === this.billEntry.owner;
  }

  deleteBillEntry(): void{
      if(!this.isCurrentUserOwner()){
        return;
      }
      this.billEntry.isDeactivated = true;
      this.http.post("/api/billEntry",this.billEntry, this.auth.getAuthHeaderObject()).subscribe(data => {
      this.billEntry = data as any;
      this.deleted.emit();
  });
  }

  formatNumber(x: number): string{
    return x?.toString().replace(".", "," );
  }

  isClosed(): boolean{
    return BillEntryRowComponent.isClosedId !== this.billEntry._id;
  }

  toggleClosed() {
    BillEntryRowComponent.isClosedId = BillEntryRowComponent.isClosedId === this.billEntry._id? undefined : this.billEntry._id;
  }

}
